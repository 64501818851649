<template>
  <div :class="$style.report">
    <Title text="Отчет по заказам" position="right" />

    <div :class="$style.report__header">
      <div :class="$style.report__header__body">
        <div :class="$style.mr10">
          <p>Дата создания заказа</p>
          <date-picker
            v-model="date"
            range
            :lang="lang"
            placeholder="Дата"
            :editable="false"
            :formatter="momentFormat"
          />
        </div>
        <div :class="$style.mr10">
          <p>Дата проведения заказа</p>
          <date-picker
            v-model="datetime"
            range
            :lang="lang"
            placeholder="Дата"
            :editable="false"
            :formatter="momentFormat"
          />
        </div>
        <div :class="$style.mr10">
          <p>Роль</p>
          <DefaultSelect
            v-model="roleId"
            :items="EmployeeNames"
            :font-size="'12px'"
            :style="{ width: '14rem' }"
            placeholder="Выбрать"
            @input="(val) => (roleId = val.id)"
          />
        </div>
        <div>
          <p>Пользователь</p>
          <DefaultSelect
            v-model="employeeId"
            :items="employees"
            :font-size="'12px'"
            :style="{ width: '14rem' }"
            placeholder="Выбрать"
            @input="(val) => (roleId = val.id)"
          />
        </div>
      </div>
      <Button
        :class="$style.report__header__button"
        v-if="canDownload"
        type="tertiary"
        center
        :disabled="loading"
        @click="getReport"
      >
        Скачать xlsx файл
      </Button>
    </div>
  </div>
</template>

<script>
import Title from '@/basic/Title'
import Button from '@/basic/Button'
import DatePicker from 'vue2-datepicker'
import ru from 'vue2-datepicker/locale/ru'
import Moment from 'moment'
import ReportsApi from '@/api/reports'
import 'vue2-datepicker/index.css'
import DefaultSelect from '../basic/DefaultSelect'
import { EmployeeNames } from '@/application/constants'
import employeesApi from '@/api/employees'

export default {
  name: 'OrdersReport',
  components: {
    Title,
    Button,
    DatePicker,
    DefaultSelect
  },
  data() {
    return {
      lang: ru,
      EmployeeNames: EmployeeNames,
      date: null,
      loading: false,
      datetime: null,
      roleId: null,
      employeeId: null,
      employees: [],
      momentFormat: {
        stringify: (date) => {
          return date ? Moment(date).format('DD-MM-YYYY') : ''
        }
      }
    }
  },
  computed: {
    startDate() {
      return this.date && this.date.length && Moment(this.date[0]).format('YYYY-MM-DD')
    },
    orderStartDate() {
      return (
        this.datetime && this.datetime.length && Moment(this.datetime[0]).format('YYYY-MM-DD')
      )
    },
    endDate() {
      return this.date && this.date.length && Moment(this.date[1]).format('YYYY-MM-DD')
    },
    orderEndDate() {
      return (
        this.datetime && this.datetime.length && Moment(this.datetime[1]).format('YYYY-MM-DD')
      )
    },
    canDownload() {
      return (this.startDate && this.endDate) || (this.orderStartDate && this.orderEndDate)
    }
  },
  async mounted() {
    try {
      const {
        data: {
          result: { rows }
        }
      } = await employeesApi.getEmployees()
      this.employees = rows.map((e) => {
        return {
          id: e.id,
          name: (e.lastName ? e.lastName : '') + ' ' + (e.firstName ? e.firstName : '') + ' ' + (e.middleName ? e.middleName : '')
        }
      })
    } catch (error) {
      console.warn(error)
    }

    if (this.$route.query.page) this.$router.push('/ordersReport')
  },
  methods: {
    async getReport() {
      try {
        this.loading = true

        const resp = await ReportsApi.getOrdersReport({
          filter: {
            createdAt: {
              start: this.startDate,
              end: this.endDate
            },
            datetime: {
              start: this.orderStartDate,
              end: this.orderEndDate
            },
            employeeId: this.employeeId,
            roleId: this.roleId
          }
        })

        if (resp?.status === 200) {
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(resp.data)
          link.download = `Отчет по заказам с ${Moment(this.startDate ? this.startDate : this.orderStartDate).format(
            'DD-MM-YYYY'
          )} по ${Moment(this.endDate ? this.endDate : this.orderEndDate).format('DD-MM-YYYY')}-${Date.now()}.xlsx`
          link.click()
        }
      } catch (error) {
        console.warn(error)
        this.openNotice('ErrorNotice', `Ошибка получения файла с сервера!`, 2500)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss">
.pagination {
  display: flex;
  margin-top: 30px;
}
.page {
  cursor: pointer;
  background-color: #4444b7;
  margin-right: 10px;
  color: #fff;
  a {
    display: inline-block;
    padding: 10px;
  }
  &.active {
    background-color: #0909f3;
  }
}
</style>

<style module lang="scss">
@import '@/assets/styles/colors.scss';
.mr10 {
  margin-right: 10px;
}
.report {
  &__header {
    &__body {
      display: flex;
      margin-bottom: 20px;
      div {
        p {
          margin-bottom: 5px;
          font-size: 14px;
          font-weight: bold;
        }
      }
    }
    &__button {
      width: 200px;
      margin-left: 30px;
      padding: 5px 15px !important;
    }
  }

  &__counter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2rem 0 1rem;
  }

  &__table {
    width: 100%;
    border: 1px solid $table-border;

    &__header {
      &_row {
        display: flex;
        justify-content: space-between;
        height: 2.5rem;
        border-bottom: 1px solid $table-border;
      }

      &_column {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
        border-right: 1px solid $table-border;
        font-size: 1.125rem;
        font-weight: 500;

        &_icon {
          cursor: pointer;
          padding: 10px;
          transition: all 0.3s ease;

          &_active {
            transform: rotate(180deg);
          }
        }

        &:first-child {
          width: 9.5%;
        }

        &:nth-child(n + 2) {
          width: 18.5%;
        }

        &:last-child {
          border: none;
        }
      }
    }

    &__content {
      position: relative;
      display: flex;
      flex-direction: column;

      &_row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 2.5rem;
        border-bottom: 1px solid $table-border;
      }

      &_column {
        display: flex;
        align-items: center;
        padding: 0.7rem 1rem;
        font-size: 0.875rem;
        font-weight: 500;

        &:first-child {
          width: 9.5%;
          justify-content: center;
        }

        &:nth-child(n + 2) {
          width: 18.5%;
        }

        &:last-child {
          border: none;
        }
      }

      &_rowLine {
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 100%;
      }

      &_line {
        border-right: 1px solid $table-border;

        &:first-child {
          width: 9.5%;
        }

        &:nth-child(n + 2) {
          width: 18.5%;
        }

        &:last-child {
          border: none;
        }
      }
    }
  }
}
</style>
